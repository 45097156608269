<template>
  <base-info-card title="Secured by">
    <v-row dense>
      <v-col cols="auto">
        <base-img
          :src="require(`@/assets/VisaSecure.jpg`)"
          color="grey lighten-1"
          width="150"
        />
      </v-col>
      <v-col cols="auto">
        <base-img
          :src="require(`@/assets/mastercardLogo.png`)"
          color="grey lighten-1"
          width="200"
        />
      </v-col>

      <v-col cols="auto">
        <v-row no-gutters>
          <v-col
            style="display: flex; justify-content: center; align-items: center;"
          >
            <base-img
              :src="require(`@/assets/bojLogo.jpeg`)"
              color="grey lighten-1"
              width="150"
            />
          </v-col> </v-row
        ><v-row no-gutters>
          <v-col
            style="display: flex; justify-content: center; align-items: center;"
            >Licensed by the Bank of Jamaica</v-col
          >
        </v-row>
      </v-col>

      <v-col cols="auto">
        <base-img
          :src="require(`@/assets/Powered-by-FAC.jpg`)"
          color="grey lighten-1"
          width="150"
        />
      </v-col>
    </v-row>
  </base-info-card>
</template>

<script>
export default {
  name: "Instagram",
};
</script>
